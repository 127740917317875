<template>
  <loader-view v-if="loading" />

  <section v-else class="py-12">
    <v-container class="py-0">
      <v-row class="mb-8">
        <v-col cols="12" sm="5" md="3" lg="2" xl="1">
          <div class="d-flex align-center justify-cente">
            <v-avatar class="border" color="accent" size="100%" tile>
              <v-img
                :src="
                  player.player.avatar ||
                  require('@/assets/utils/profile-frame.png')
                "
                :aspect-ratio="4 / 4"
              />
            </v-avatar>
          </div>
        </v-col>

        <v-col>
          <div
            class="d-flex flex-column fill-height justify-center pl-0 pl-md-8"
          >
            <div class="d-flex align-center">
              <h1 class="text-h4 overtext--text mr-2">
                {{ player.player.nickname }}
              </h1>

              <v-icon color="primary">
                {{
                  player.player.schoolCertificate
                    ? "mdi-check-decagram-outline"
                    : ""
                }}
              </v-icon>
            </div>

            <div class="text-h6 font-weight-regular subtext--text mb-4">
              {{ player.firstName }} {{ player.lastName }}
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>

    <v-card color="surface" class="mb-10" flat tile>
      <v-container class="pb-0 pt-6">
        <v-tabs
          background-color="transparent"
          slider-color="primary"
          color="subtext"
          show-arrows
        >
          <v-tab
            v-for="(item, i) in tabs"
            exact-active-class="overtext--text"
            :key="i"
            :to="`/league/player-details/${$route.params.id}${item.path}`"
          >
            {{ item.label }}
          </v-tab>
        </v-tabs>
      </v-container>
    </v-card>

    <v-container class="py-0">
      <router-view :player="player" @refresh="getData()" />
    </v-container>
  </section>
</template>

<script>
import { getPlayer } from "@/services/league/players.js";

export default {
  data() {
    return {
      loading: true,
      player: null,
      tabs: [
        {
          label: "Geral",
          path: "/",
        },
        {
          label: "Equipes",
          path: "/teams",
        },
      ],
    };
  },

  beforeMount() {
    this.getData();
  },

  methods: {
    async getData() {
      try {
        await getPlayer(this.$route.params.id).then((res) => {
          this.player = res;
          this.loading = false;
        });
      } catch (err) {
        console.log(err);
        this.$router.push({ path: "/league/players" });
      }
    },
  },
};
</script>

<style></style>
